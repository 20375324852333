import { createContext, useContext } from 'react';

export const GlobalContext = createContext(undefined);

export default function useGlobalContextProvider() {
  const context = useContext(GlobalContext);

  if (context === undefined)
    throw new Error('useGlobalContextProvider must be used within GlobalContext.Provider');

  return context;
}
